
const ttsSocketUz = new WebSocket(`wss://backend.diyoraai.uz/ws/tts?accept_language=uz`)
const ttsSocketEn = new WebSocket(`wss://backend.diyoraai.uz/ws/tts?accept_language=en`)
const ttsSocketRu = new WebSocket(`wss://backend.diyoraai.uz/ws/tts?accept_language=ru`)


/* UZ */
ttsSocketUz.onopen = function(event){
    console.log("tts uz websocket connected .........")
}

ttsSocketUz.onmessage = function(event) {
    if (typeof event.data === 'string') {
        
        if (event.data === 'TTS processing complete') {
            console.log('TTS uz processing complete');
        }
        console.log(event.data)
    } else {
        // Handle binary audio data
        let audioBlob = new Blob([event.data], { type: 'audio/wav' });
        const audioUrl = URL.createObjectURL(audioBlob);
        let audioplay = document.getElementById("audioplay");
        audioplay.src = audioUrl;
        audioplay.play();
       
    }
};

ttsSocketUz.onerror = function(error) {
    console.error('tts uz WebSocket error:', error);
};

ttsSocketUz.onclose = function(event) {
    console.log('tts uz WebSocket connection closed', event);
};

/* /UZ */

/* EN */
ttsSocketEn.onopen = function(event){
    console.log("tts en websocket connected .........")
}

ttsSocketEn.onmessage = function(event) {
    if (typeof event.data === 'string') {
        
        if (event.data === 'TTS processing complete') {
            console.log('TTS processing complete');
        }
    } else {
        // Handle binary audio data
        let audioBlob = new Blob([event.data], { type: 'audio/wav' });
        const audioUrl = URL.createObjectURL(audioBlob);
        let audioplay = document.getElementById("audioplay");
        audioplay.src = audioUrl;
        audioplay.play();
        
    }
};

ttsSocketEn.onerror = function(error) {
    console.error('tts en WebSocket error:', error);
};

ttsSocketEn.onclose = function(event) {
    console.log('tts en WebSocket connection closed', event);
};
/* /EN */

/* RU */
ttsSocketRu.onopen = function(event){
    console.log("tts ru websocket connected .........")
}

ttsSocketRu.onmessage = function(event) {
    if (typeof event.data === 'string') {
        
        if (event.data === 'TTS processing complete') {
            console.log('TTS processing complete');
        }
    } else {
        // Handle binary audio data
        let audioBlob = new Blob([event.data], { type: 'audio/wav' });
        const audioUrl = URL.createObjectURL(audioBlob);
        let audioplay = document.getElementById("audioplay");
        audioplay.src = audioUrl;
        audioplay.play();
        
    }
};

ttsSocketRu.onerror = function(error) {
    console.error('tts ru WebSocket error:', error);
};

ttsSocketRu.onclose = function(event) {
    console.log('tts ru WebSocket connection closed', event);
};
/* /RU */


function TTS(text){
    // text = text.replace("O'", "Oʻ");
    // text = text.replace("o'", "oʻ");
    // text = text.replace("G'", "Gʻ");
    // text = text.replace("g'", "gʻ");
    const language = sessionStorage.getItem('language');
    
    if (text){
        if(language==='en'){
            ttsSocketEn.send(JSON.stringify({text: text}));
        }else if(language==='ru'){
            ttsSocketRu.send(JSON.stringify({text: text}));
        }else{
            ttsSocketUz.send(JSON.stringify({text: text}));
        };
        console.log("socketga jo'natildi")
    };
    
};


export default TTS;