
import STT from "./STT";
import visualizer from './images/visualizer.png'
import ChatAreaFooter from "./ChatAreaFooter";
import Messages from "./Messages";
import { useRef } from "react";

function ChatArea({ messages, setMessages, socket, playAudio, baseUrl }){
    const ttsSocket = useRef(null)

    return (
        <div className="chat-area">
          <div className="chat-area-header">
            <div className="gradient blue"></div>
            <div className="gradient binafsha"></div>
            <div id="face-div">
              <img id="face" className="face" src={visualizer} playsInline loop preload="yes" autoPlay x-webkit-airplay="allow"></img>
            </div>
            <div className="key-word">
              <h3 id="key-word"><strong>{'"Diyora" '}</strong>{'deb ayting'}</h3>
            </div>
          </div>
          <Messages 
            messages={messages} 
            setMessages={setMessages} 
            socket={socket}
            playAudio={playAudio}
            ttsSocket={ttsSocket}/>
          <ChatAreaFooter socket={socket} setMessages={setMessages} baseUrl={baseUrl}/>
          <STT chatbotSocket={socket} setMessages={setMessages}/>
       </div>
    );
}

export default ChatArea;