import TTS from '../TTS';
import waitingGif from '../images/wait.gif'
import addMessage from './addMessage';

function SocketEvents({SocketUz, SocketEn, SocketRu, chatbotSocket, setMessages}){
  
    // socket uz
    SocketUz.onmessage = function(event){
        const stateIcon = document.getElementById('state-icon');
        stateIcon.src = waitingGif;
        const data = JSON.parse(event.data)
        if (data.transcript === "Speech not understood" || data.transcript === "Audio could not be understood"){
            addMessage("Tushunolmadim, qayta gapira olasizmi?", '', setMessages)
            TTS("Tushunolmadim, qayta gapira olasizmi?")
        } else{
            addMessage(data.transcript, "owner", setMessages);
            chatbotSocket.send(data.transcript);
        }
    }

    SocketUz.onerror = function(error) {
        console.error('WebSocket xatoligi:', error);
    };
    
    SocketUz.onclose = function(event) {
        console.log('WebSocket ulanishi yopildi', event);
    };
    // end uz

    // socket En
    SocketEn.onmessage = function(event){
        const stateIcon = document.getElementById('state-icon');
        stateIcon.src = waitingGif;
        const data = JSON.parse(event.data)
        if (data.transcript === "Speech not understood" || data.transcript === "Audio could not be understood"){
            addMessage("I didn't understood, can you try again?", '', setMessages)
            TTS("I didn't understood, can you try again?")
        } else{
            addMessage(data.transcript, "owner", setMessages);
            chatbotSocket.send(data.transcript);
        }
    }

    SocketEn.onerror = function(error) {
        console.error('WebSocket xatoligi:', error);
    };
    
    SocketEn.onclose = function(event) {
        console.log('WebSocket ulanishi yopildi', event);
    };
    // end en

    // socket ru
    SocketRu.onmessage = function(event){
        const stateIcon = document.getElementById('state-icon');
        stateIcon.src = waitingGif;
        const data = JSON.parse(event.data)
        console.log(data.transcript)
        console.log( data.transcript === "Audio could not be understood")
        if (data.transcript === "Speech not understood" || data.transcript === "Audio could not be understood"){
            addMessage("Я не понимаю, можешь еще раз сказать?", '', setMessages)
            TTS("Я не понимаю, можешь еще раз сказать?")
        } else{
            addMessage(data.transcript, "owner", setMessages);
            chatbotSocket.send(data.transcript);
        }
    }

    SocketRu.onerror = function(error) {
        console.error('WebSocket xatoligi:', error);
    };
    
    SocketRu.onclose = function(event) {
        console.log('WebSocket ulanishi yopildi', event);
    };
    // end ru
};

export default SocketEvents;