import TTS from './TTS';
import Message from "./Message";
import { useEffect, useRef } from "react";
import waitingGif from './images/wait.gif'

function Messages({ messages, setMessages, socket, playAudio, ttsSocket }) {
    const messagesEndRef = useRef(null);
    
    useEffect(() => {
        const handleMessage = (message) => {
            message = message.data
            const newMessage = {text: message, type: ''};

            setMessages((prevMessages) => [...prevMessages, newMessage]);
            let chat_area_main = document.querySelector('.chat-area-main');
            setTimeout(() => {
                chat_area_main.scrollTop = chat_area_main.scrollHeight;
                
            }, 100);
            TTS(message);
            let gifState = document.getElementById('gif-state');
            // gifState.innerText = "Kuting!"
            const stateIcon = document.getElementById('state-icon');
            stateIcon.src = waitingGif;
            
        };  
        // Listen for incoming messages
        socket.addEventListener('message', handleMessage);
        
        return () => {
            socket.removeEventListener('message', handleMessage);
            socket.onclose = () => console.log("socket closed");
        };
    }, [setMessages]);

    return (
        <div ref={messagesEndRef} className="chat-area-main">
            <Message messages={messages} messagesEndRef={messagesEndRef} />
        </div>
    );
}

export default Messages;
