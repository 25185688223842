import { useEffect, useRef } from 'react';
import audioVisualizer from './images/audio_visualizer.gif'
import recordingGif from "./images/recording.gif";
import sendMessageIcon from './images/send_icon.png';
import isUserSpeaking from './utils/isUserSpeaking';
import KeyWordRecognition from './utils/KeyWordRecognition';
import defaultFace from './images/visualizer.png';
import speakingFace from './images/visualizer.gif';
import SocketEvents from './utils/STTSocketEvents';

// const socket = new WebSocket(`wss://backend.diyoraai.uz/v3/stt-ws`);
const sttSocketUz = new WebSocket(`wss://backend.diyoraai.uz/ws/stt?accept_language=uz`)
const sttSocketEn = new WebSocket(`wss://backend.diyoraai.uz/ws/stt?accept_language=en`)
const sttSocketRu = new WebSocket(`wss://backend.diyoraai.uz/ws/stt?accept_language=ru`)


const STT = ({ chatbotSocket, setMessages }) => {
    
    const recognitionRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const whenAudioFinish = useRef(null);
    const silenceDuration = 1500;
    const waitingTimeAfterAudioPlayEnd = 5000;
    let silenceStart = null;
    let userSpeaking = false;
    let chunks = [];

    useEffect(() => {
        const face = document.getElementById('face');
        const stateIcon = document.getElementById('state-icon');
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                const mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' });
                mediaRecorderRef.current = mediaRecorder;
                const audioContext = new (window.AudioContext || window.webkitAudioContext)();
                const analyser = audioContext.createAnalyser();
                const source = audioContext.createMediaStreamSource(stream);
                source.connect(analyser);

                mediaRecorder.onstart = () =>{
                    stateIcon.src = recordingGif;
                    const recordingSound = document.getElementById('recording-sound')
                    recordingSound.play();
                };

                mediaRecorder.ondataavailable = (event) => {
                    
                    if (Date.now() - whenAudioFinish.current > waitingTimeAfterAudioPlayEnd && whenAudioFinish.current){
                        console.log(whenAudioFinish, "shart bo'yicha o'chirilmoqda")
                        mediaRecorder.stop();
                    }

                    if (event.data.size > 0) {
                        const isSpeaking = isUserSpeaking(analyser)
                        // console.log(isSpeaking, "isspeaking")
                        if (isSpeaking){
                            chunks.push(event.data);
                            silenceStart = null
                            userSpeaking = true;
                            whenAudioFinish.current = Date.now();
                        }
                        else {
                           
                            if (silenceStart === null && userSpeaking){
                                silenceStart = Date.now();
                                chunks.push(event.data);
                                
                            } else if (silenceStart != null && Date.now() - silenceStart > silenceDuration) {
                                mediaRecorder.stop();
                                userSpeaking = false;
                                silenceStart = null;
                                const combinedBlob = new Blob(chunks, { type: 'audio/webm' });
                                chunks = [];
                                console.log("sttga jo'natildi   ")
                                var language = sessionStorage.getItem('language')
                                console.log(language)
                               
                                if(true){
                                    const formData = new FormData();
                                    // `audio_file` kaliti bilan faylni qo'shish
                                    formData.append('file', combinedBlob, 'recording.webm');

                                    // So'rovni `fetch` yordamida yuborish
                                    fetch('https://landetect.diyoraai.uz/detect-language/', {
                                        method: 'POST',
                                        body: formData
                                    })
                                    .then((response) => response.json())
                                    .then((data) => {
                                        console.log('Serverdan javob:', data);
                                        sessionStorage.setItem('language', data.lan)
                                        if (data.lan === 'ru'){
                                            console.log('ru sttga jonatildi')
                                            sttSocketRu.send(combinedBlob);
                                        }else if(data.lan === 'en'){
                                            console.log('en sttga jonatildi')
                                            sttSocketEn.send(combinedBlob);
                                        }else{
                                            console.log("uz sttga jo'natildi")
                                            sttSocketUz.send(combinedBlob);
                                        }; 
                                        
                                    })
                                    .catch((error) => console.error('Xatolik yuz berdi:', error));
                                    
                                }else{
                                    if (language === 'ru'){
                                        console.log('ru sttga jonatildi')
                                        sttSocketRu.send(combinedBlob);
                                    }else if(language === 'en'){
                                        console.log('en sttga jonatildi')
                                        sttSocketEn.send(combinedBlob);
                                    }else{
                                        console.log("uz sttga jo'natildi")
                                        sttSocketUz.send(combinedBlob);
                                    };
                                };   
                                
                            }else{
                                chunks.push(event.data);
                            }
                            
                        };
                        
        
                    }
                   
                };
              
                mediaRecorder.onstop = () => {
                    chunks = [];
                    stateIcon.src = sendMessageIcon;
                    const stopRecordingSound = document.getElementById('stop-recording-sound')
                    stopRecordingSound.play();
                    
                };

            })
            .catch(error => {
                console.error('Error accessing microphone:', error);
            });


        let audioplay = document.getElementById("audioplay");
        
        audioplay.addEventListener('play', () => {
            face.src = speakingFace;
            stateIcon.src = audioVisualizer;
            mediaRecorderRef.current.stop();
        });

        audioplay.addEventListener('ended', () => {
            face.src = defaultFace;
            if (mediaRecorderRef.current.state === "inactive"){
                mediaRecorderRef.current.start(300);
            }
            stateIcon.src = recordingGif;
            whenAudioFinish.current = Date.now();
    
        }); 
        
        return () => {
            if (recognitionRef.current) {
                recognitionRef.current.stop();
            }
        };
    }, []);

    return (
        <section>
            <KeyWordRecognition mediaRecorderRef={mediaRecorderRef} chatbotSocket={chatbotSocket} setMessages={setMessages} whenAudioFinish={whenAudioFinish}/>
            <SocketEvents SocketUz={sttSocketUz} SocketEn={sttSocketEn} SocketRu={sttSocketRu} chatbotSocket={chatbotSocket} setMessages={setMessages}/>
        </section>
    );
};

export default STT;
