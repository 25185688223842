
import ChatArea from './ChatArea';
import HiddenAudioPlayer from './AudioPlayer';
import './App.css';
import { useState, useRef } from 'react';

const socket = new WebSocket("wss://bot.diyoraai.uz/chatbot/parapay3");
// const socket = new WebSocket("ws://77.220.212.32:8000/chatbot/parapay3");

function App() {

  const [messages, setMessages] = useState([])
  const audioRef = useRef(null);


  const playAudio = () => {
      let audioplay = document.getElementById("audioplay");
      audioplay.play();
  }; 

  return (
    <div className="app">
      <div className="wrapper">
          <HiddenAudioPlayer 
            src={""}
            audioRef={audioRef}/>
          <ChatArea 
            messages={messages} 
            setMessages={setMessages} 
            socket={socket}
            playAudio={playAudio}/>
            
      </div>
    </div>
  );
}

export default App;
