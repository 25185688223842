import TTS from '../TTS';
import { useEffect } from 'react';
import addMessage from './addMessage';
import waitingGif from '../images/wait.gif'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';


const keywordsDiyora = ['diyora', 'dealer', 'deodora', 'deore', 'dora', 'the order', 'diara', 'tiara', 'laura', 'yoda', 'deanna', 'toyota', 'daughter', 'louisiana', 'diana', 'the other', 'de', 'rihanna'];
const keywordsSalom = ['salom', 'salam', 'salaam', 'salon', 'salome', 'salem']; 
const salomText = "Assalomu alaykum hurmatli mijoz, sizga qanday yordam bera olishim mumkin?";

function KeyWordRecognition({mediaRecorderRef, chatbotSocket, setMessages, whenAudioFinish}){
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
      } = useSpeechRecognition();
    const stateIcon = document.getElementById('state-icon');

    SpeechRecognition.startListening({ continuous: true, language: 'en', interimResults: false });
    
    useEffect(()=>{
      // console.log(transcript);
      if (keywordsDiyora.includes(transcript.toLowerCase()) ) {
            whenAudioFinish.current = Date.now();
            if (mediaRecorderRef.current.state === 'inactive'){
                mediaRecorderRef.current.start(300);  
            } else {
                mediaRecorderRef.current.stop();
                mediaRecorderRef.current.start(300);
            }
                        
        } else if (keywordsSalom.includes(transcript.toLowerCase())){
            chatbotSocket.send('newchat');
            addMessage(salomText, '', setMessages, true)
            TTS(salomText);
            stateIcon.src = waitingGif;
        };

      resetTranscript();
    }, [transcript]);
    

    if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>;
      }
    
  
    return null;
};

export default KeyWordRecognition;